<template>
  <div>
    <div class="page-header d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
          <router-link :to="{ name: 'reports' }">
            <button class="btn btn-control">
              <img src="@/assets/icons/bold-icon previous.svg" alt="Return back">
            </button>
          </router-link>
          <h3 class="page-header-title ms-2">
            Report
          </h3>
          <span class="subtitle ms-3">
            {{ report.name }}
          </span>
          <button class="btn btn-control ms-2" @click="$emit('edit')">
            <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit">
          </button>
        </div>
      <div class="d-flex align-items-center" v-if="changed === true">
        <button class="btn bg-green ms-3" @click="$emit('saveReport')">
          Save Customization
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportHeader',
  props: {
    reportData: Object,
    changed: Boolean
  },
  data() {
    return {
      report: this.reportData,
      isChanged: this.changed
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  a {
    text-decoration: none;
  }
}
</style>

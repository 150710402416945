<template>
  <div>
    <!-- Page header -->
    <report-header :reportData="reportData" :changed="reportChanged"
                   @edit="modals.renameReport = true"
                   @saveReport="modals.addReportName = true" />

    <!--  Report Filtration  -->
    <div class="report-details">
      <!-- Select report type -->
      <div class="row data-category">
        <div class="col-md-5 col-lg-6 col-xl-3 mt-3 mt-md-0">
          <!-- Multiselect for select report type -->
          <multiselect v-model="typeValue"
                       :option-height="104" :options="multiSelect.types" :searchable="false"
                       :show-labels="false" label="title"
                       @select="selectType"
                       placeholder="" selectedLabel="Selected" track-by="title">
            <template slot="singleLabel" slot-scope="props">
              <div>
                <span class="report-type">
                  Type
                </span>
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="col-md-5 col-lg-6 col-xl-3 mt-3 mt-md-0">
          <!-- Multiselect for select agent -->
          <multiselect v-if="typeValue.type === 'agent'" v-model="selectedAgent"
                       :option-height="104" :options="multiSelect.agents" :searchable="false"
                       :show-labels="false" label="title"
                       @select="selectAgent"
                       placeholder="" selectedLabel="Selected" track-by="title">
            <template slot="singleLabel" slot-scope="props">
              <div>
                <img alt="Agent" src="@/assets/icons/icon-profile.svg">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>

          <!-- Multiselect for select agent -->
          <multiselect v-if="typeValue.type === 'processor'" v-model="selectedProcessor"
                       :option-height="104" :options="multiSelect.processors" :searchable="false"
                       :show-labels="false" label="title"
                       @select="selectProcessor"
                       placeholder="" selectedLabel="Selected" track-by="title">
            <template slot="singleLabel" slot-scope="props">
              <div>
                <img alt="Agent" src="@/assets/icons/icon-profile.svg">
                <span class="option__title">{{ props.option.title }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="">{{ props.option.title }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>

      <!-- Table filtration row -->
      <div class="row mt-3">
        <div class="col-md-6 col-lg-4 table-filter">
          <div id="table-filter" class="position-relative" parent>
            <div class="tippy-dropdown" name="tableFilter">
              <span class="report-type">
                Show  <span v-if="tableFilterPositions.revenue === true && typeValue.type !== 'processor'">Revenue,</span>
                      <span v-if="tableFilterPositions.units === true">Units,</span>
                      <span v-if="typeValue.type !== 'processor' && tableFilterPositions.expense === true || reportProcessor === true && tableFilterPositions.expense === true">Expense</span>
                      <span v-if="tableFilterPositions.turnTime === true && typeValue.type === 'processor' || tableFilterPositions.turnTime === true && selectAgent === true">Turn Time</span>
                <span class="normal" v-if="typeValue.type === 'company'">grouped by {{ this.groupedBy }}</span>
              </span>
              <img alt="Arrow" src="@/assets/icons/icon-drop-down-expand.svg">
            </div>
            <tippy animateFill="false"
                   arrowType="round"
                   boundary="window"
                   class="setDate"
                   distance="1"
                   hideOnClick="true"
                   :appendTo="tableFilterAppend"
                   interactive="true"
                   placement="bottom-center"
                   sticky="true" theme="dropdown" to="tableFilter"
                   trigger="click" zIndex="99">
              <div class="tippy-filter-table">
                <div class="row">
                  <div class="col-5 p-0">
                    <span>
                      Show
                    </span>
                    <div class="form-check mt-2" v-if="typeValue.type !== 'processor' || reportProcessor === true">
                      <input class="form-check-input" type="checkbox" id="fieldRevenue" v-model="tableFilterPositions.revenue">
                      <label class="form-check-label" for="fieldRevenue">
                        Revenue
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="checkbox" id="fieldUnits" v-model="tableFilterPositions.units">
                      <label class="form-check-label" for="fieldUnits">
                        Units
                      </label>
                    </div>
                    <div class="form-check mt-2" v-if="typeValue.type === 'processor' && reportProcessor === false">
                      <input class="form-check-input" type="checkbox" id="fieldFilterTurnTime" v-model="tableFilterPositions.turnTime">
                      <label class="form-check-label" for="fieldFilterTurnTime">
                        Turn Time
                      </label>
                    </div>
                    <div class="form-check mt-2" v-if="typeValue.type !== 'processor' || reportProcessor === true">
                      <input class="form-check-input" type="checkbox" id="fieldExpense" v-model="tableFilterPositions.expense">
                      <label class="form-check-label" for="fieldExpense">
                        Expense
                      </label>
                    </div>
                  </div>
                  <div class="col-7 p-0" v-if="typeValue.type === 'company'">
                    <span>
                      Grouped by
                    </span>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" id="fieldMonth" value="Month" v-model="groupedBy">
                      <label class="form-check-label" for="fieldMonth">
                        Month
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" id="fieldYear" value="Year" v-model="groupedBy">
                      <label class="form-check-label" for="fieldYear">
                        Year
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" id="fieldLoanType" value="Loan Type" v-model="groupedBy">
                      <label class="form-check-label" for="fieldLoanType">
                        Loan Type
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" id="fieldState" value="State" v-model="groupedBy">
                      <label class="form-check-label" for="fieldState">
                        State
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" id="fieldCategoryExpense" value="Category" v-model="groupedBy">
                      <label class="form-check-label" for="fieldCategoryExpense">
                        Category (Expense)
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="radio" id="fieldVendorExpense" value="Vendor" v-model="groupedBy">
                      <label class="form-check-label" for="fieldVendorExpense">
                        Vendor (Expense)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </tippy>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 mt-3 mt-md-0">
          <div id="pickDate" >
            <!-- Dropdown for date -->
            <div class="tippy-dropdown" name="chooseDate">
              {{ datePicked.title }} <span>{{ datePicked.range }}</span>
              <img alt="Arrow" src="@/assets/icons/icon-drop-down-expand.svg">
            </div>
            <tippy animateFill="false"
                   arrowType="round"
                   boundary="window"
                   class="setDate"
                   distance="1"
                   hideOnClick="true"
                   interactive="true"
                   placement="bottom-center"
                   :appendTo="append"
                   sticky="true" theme="dropdown" to="chooseDate"
                   trigger="click" zIndex="99">
              <div class="setDate-block">
                <ul>
                  <li v-for="(date, index) in dateOptions" :key="index"
                      :class="{'is-checked': date.isChecked === true}" @click="setDate(date.id)">
                    {{ date.title }} <span>({{ date.date }})</span>
                  </li>
                </ul>
                <div class="custom-date border-top-light">
                  <div class="row">
                    <div class="col-6 ps-0">
                      <date-range-picker
                        ref="picker"
                        v-model="dateRange"
                        class="daterange-control"
                        opens="center"
                        :singleDatePicker="true"
                        :auto-apply="true"
                        :timePicker="false"
                        :time-picker24-hour="false"
                        :ranges="false"
                        @select="datepickerSelect"
                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }">
                        <template v-slot:input="picker">
                          {{ picker.startDate | date }}
                          <img src="@/assets/icons/icon-calender.svg" class="position-absolute" alt="">
                        </template>
                      </date-range-picker>

                    </div>
                    <div class="col-6 pe-0">
                      <date-range-picker
                        ref="picker"
                        v-model="dateRangeEnd"
                        class="daterange-control"
                        opens="center"
                        :singleDatePicker="true"
                        :auto-apply="true"
                        :timePicker="false"
                        :time-picker24-hour="false"
                        :ranges="false"
                        @select="datepickerSelectEnd"
                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }">
                        <template v-slot:input="picker">
                          {{ picker.startDate | date }}
                          <img src="@/assets/icons/icon-calender.svg" class="position-absolute" alt="">
                        </template>
                      </date-range-picker>
                    </div>
                  </div>
                </div>
              </div>
            </tippy>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 mt-3 mt-lg-0">
          <div id="sortable" parent>
            <!-- Dropdown for table filter -->
            <div class="tippy-dropdown sortable" name="sortable" >
              <span>Sort by</span> {{ sortBy }} ({{ order }})
              <img alt="Arrow" src="@/assets/icons/icon-drop-down-expand.svg">
            </div>
            <tippy animateFill="false"
                   arrowType="round"
                   boundary="window"
                   class="setDate"
                   distance="1"
                   hideOnClick="true"
                   interactive="true"
                   :appendTo="sortableAppend"
                   placement="bottom-center"
                   sticky="true" theme="dropdown" to="sortable"
                   trigger="click" zIndex="99">
              <div class="sortable-block">
                <span>Sort by</span>
                <div class="form-check" v-if="typeValue.type === 'processor' && tableFilterPositions.turnTime === true">
                  <input class="form-check-input" type="radio" id="fieldTurnTime" value="Turn Time" v-model="sortBy">
                  <label class="form-check-label" for="fieldTurnTime">
                    Turn Time
                  </label>
                </div>
                <div class="form-check mt-3 mb-3"
                     v-if="typeValue.type !== 'processor' && tableFilterPositions.revenue === true">
                  <input class="form-check-input" type="radio" value="Revenue" id="fieldSortRevenue" v-model="sortBy">
                  <label class="form-check-label" for="fieldSortRevenue">
                    Revenue
                  </label>
                </div>
                <div class="form-check mt-3 mb-3" v-if="tableFilterPositions.units === true">
                  <input class="form-check-input" type="radio" value="Units" id="fieldSortUnits" v-model="sortBy">
                  <label class="form-check-label" for="fieldSortUnits">
                    Units
                  </label>
                </div>
                <div class="form-check mt-3 mb-3"
                     v-if="typeValue.type !== 'processor' && tableFilterPositions.expense === true" >
                  <input class="form-check-input" type="radio" value="Expense" id="fieldSortExpense" v-model="sortBy">
                  <label class="form-check-label" for="fieldSortExpense">
                    Expense
                  </label>
                </div>
                <span>Order</span>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="fieldHighLow" value="Hi-Lo" v-model="order">
                  <label class="form-check-label" for="fieldHighLow">
                    High-Low
                  </label>
                </div>
                <div class="form-check mt-3">
                  <input class="form-check-input" type="radio" value="Lo-Hi" id="fieldLowHigh" v-model="order">
                  <label class="form-check-label" for="fieldSortUnits">
                    Low-High
                  </label>
                </div>
              </div>
            </tippy>
          </div>
        </div>
        <div class="col-md-6 col-lg-2 mt-3 mt-lg-0">
          <button class="btn btn-outline-dark w-100">
            Run Report
          </button>
        </div>
      </div>
    </div>

    <!--  Loading spinner  -->
    <modal-spinner v-if="ui.loading === true" class="mt-4"/>

    <!--  Filtration result  -->
    <div v-if="ui.loading === false">
      <!-- Report for company -->
      <report-company :tableFilter="tableFilterPositions" v-if="typeValue.type=== 'company'"/>

      <!-- Report for single agent -->
      <report-single-agent :tableFilter="tableFilterPositions" :agentData="agentData" v-if="reportAgent === true" />

      <!-- Report for all agent -->
      <report-all-agent :tableFilter="tableFilterPositions" v-if="typeValue.type === 'agent' && reportAgent !== true" @showAgentReport="toggleAgentReport" />

      <!-- Report for processors -->
      <report-all-processors :tableFilter="tableFilterPositions" v-if="typeValue.type === 'processor' && reportProcessor !== true"/>

      <!-- Report for single processor -->
      <report-single-processor :tableFilter="tableFilterPositions" :processorData="processorData" v-if="typeValue.type === 'processor' && reportProcessor === true" />
    </div>

    <!-- Modal Rename Report -->
    <vue-page-transition name="fade">
      <report-rename-modal v-if="modals.renameReport" @close="modals.renameReport = false" />
    </vue-page-transition>

    <!-- Add name of report -->
    <vue-page-transition name="fade">
      <add-report-name v-if="modals.addReportName" @close="modals.addReportName = false" />
    </vue-page-transition>
  </div>
</template>

<script>
import ReportHeader from "./includes/ReportHeader";
import ReportCompany from "./includes/ReportCompany";
import Multiselect from 'vue-multiselect'
import ReportAllAgent from "./includes/ReportAllAgent";
import ReportAllProcessors from "./includes/ReportAllProcessors";
import ModalSpinner from "../../components/modal/ModalSpinner";
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import ReportSingleAgent from "./includes/ReportSingleAgent";
import ReportSingleProcessor from "./includes/ReportSingleProcessor";
import ReportRenameModal from "./modals/ReportRenameModal";
import AddReportName from "./modals/AddReportName";

export default {
  name: 'ReportsAgent',
  components: {
    AddReportName,
    ReportRenameModal,
    ReportSingleProcessor,
    ReportSingleAgent,
    ModalSpinner,
    ReportAllProcessors,
    ReportAllAgent,
    ReportCompany,
    ReportHeader,
    Multiselect,
    DateRangePicker
  },
  data() {
    return {
      typeValue: {title: 'Company Report', type: 'company'},
      selectedAgent: {id: 0, title: 'All Agent'},
      selectedProcessor: {id: 0, title: 'All Processors'},
      sortingValue: {},
      tableFilter: {},
      multiSelect: {
        types: [
          {title: 'Agent Report', type: 'agent'},
          {title: 'Processor Report', type: 'processor'},
          {title: 'Company Report', type: 'company'},
        ],
        agents: [
          {id: 0, title: 'All Agent'},
          {id: 1, title: 'Nathan Kowarsky'},
          {id: 2, title: 'Pavel Uvarov'},
        ],
        processors: [
          {id: 0, title: 'All Processors'},
          {id: 1, title: 'Nathan Kowarsky'},
          {id: 2, title: 'Pavel Uvarov'},
        ],
        tableFilters: [],
        sortables: [],
      },
      tableFilterPositions: {
        revenue: true,
        units: true,
        expense: true,
        turnTime: false
      },
      dateOptions: [],
      datePicked: {
        title: '',
        range: '',
      },
      ui: {
        loading: false
      },
      dateRange: {
        startDate: new Date(),
      },
      dateRangeEnd: {
        startDate: new Date(),
      },
      customRange: {
        startDate: moment(String(new Date())).format('MMM DD YYYY'),
        endDate: moment(String(new Date())).format('MMM DD YYYY'),
      },
      pickedDateRange: '',
      sortBy: 'Turn Time',
      order: 'Hi-Lo',
      groupedBy: 'Month',
      reportAgent: false,
      reportProcessor: false,
      modals: {
        renameReport: false,
        addReportName: false
      },
      reportData: {
        name: 'Test Report'
      },
      reportChanged: false,
      agentData: null,
      processorData: null
    }
  },
  methods: {
    setDateList() {
      // Func for getting date for custom datepicker dropdown
      const date = new Date();
      const previousDate = new Date();
      const formatOptions = {
        year: 'numeric', month: 'short', day: 'numeric'
      }

      // Get previous month
      previousDate.setDate(1);
      previousDate.setMonth(previousDate.getMonth() - 1);

      this.dateOptions = [
        {
          id: 1,
          title: 'This month',
          date: new Date(date.getFullYear(), date.getMonth(), 1).toLocaleString('en-us', formatOptions) + ' - ' +
            new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleString('en-us', formatOptions),
          isChecked: false
        },
        {
          id: 2,
          title: 'Last Month',
          date: previousDate.toDateString() + ' - ' + new Date(previousDate.getFullYear(), date.getMonth(), 0).toLocaleString('en-us', formatOptions),
          isChecked: false
        },
        {
          id: 3,
          title: 'Last 30 days',
          date: new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleString('en-us', formatOptions) + ' - ' +
            new Date().toLocaleString('en-us', formatOptions),
          isChecked: false
        },
        {
          id: 4,
          title: 'This Year',
          date: new Date(new Date().getFullYear(), 0, 1)
              .toLocaleString('en-us', formatOptions) + ' - ' +
            new Date().toLocaleString('en-us', formatOptions),
          isChecked: true
        },
        {
          id: 5,
          title: 'Last Year',
          date: new Date(new Date().getFullYear() - 1, 0, 1)
              .toLocaleString('en-us', formatOptions) + ' - ' +
            new Date(new Date().getFullYear(), 0, 0).toLocaleString('en-us', formatOptions),
          isChecked: false
        },
        {
          id: 6,
          title: 'Alltime',
          date: new Date(2019, 0, 1).toLocaleString('en-us', formatOptions) + ' - ' +
            new Date().toLocaleString('en-us', formatOptions),
          isChecked: false
        },
        {
          id: 7,
          title: 'Custom Range',
          date: this.customRange.startDate + ' - ' + this.customRange.endDate,
          isChecked: false
        },
      ];

      // set checked date
      this.dateOptions = this.dateOptions.map(el => {
        if (el.isChecked === true) {
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
        }
        return el;
      });
    },
    setDate(id) {
      // Func for set date in custom date picker
      this.dateOptions = this.dateOptions.map((el) => {
        if (el.id === id) {
          el.isChecked = true;
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
        } else {
          el.isChecked = false;
        }
        return el;
      });
    },
    datepickerSelect(val) {
      // Func when pick start date and set up in variable
      this.customRange.startDate = moment(String(val.startDate)).format('MMM DD YYYY');

      // remove checked for other date item
      this.dateOptions = this.dateOptions.map(el => {
        if (el.title === 'Custom Range') {
          el.isChecked = true;
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
        } else {
          el.isChecked = false
        }
        return el;
      })
    },
    datepickerSelectEnd(val) {
      // Func when pick end date and set up in variable
      this.customRange.endDate = moment(String(val.startDate)).format('MMM DD YYYY');

      // remove checked for other date item
      this.dateOptions = this.dateOptions.map(el => {
        if (el.title === 'Custom Range') {
          el.isChecked = true;
          this.datePicked.range = el.date;
          this.datePicked.title = el.title;
        } else {
          el.isChecked = false
        }
        return el;
      })
    },
    toggleAgentReport(val) {
      // @TODO need make get request for get agent data

      // Change multiselect selected data
      this.selectedAgent.id = val;
      this.selectedAgent.title = val.title;

      this.ui.loading = true;
      setTimeout(() => {
        this.typeValue.type = 'agent';
        this.reportAgent = true;
        this.ui.loading = false;
      }, 1000)
    },
    selectType(val) {
      // Func for select report type
      this.reportChanged = true;
      if (val.type === 'agent') {
        this.selectedAgent = {id: 0, title: 'All Agent'};
        this.reportAgent = false;
      } else if (val.type === 'processor') {
        this.selectedProcessor = {id: 0, title: 'All Processor'};
        this.reportProcessor = false;
      }
    },
    selectAgent(val) {
      // Func for select report agent
      this.reportChanged = true;
      this.ui.loading = true;
      setTimeout(() => {
        this.typeValue.type = 'agent';
        this.agentData = {id: 1, name: 'Nathan Kowarsky', position: 'Position', ranked: '01'};
        this.reportAgent = val.title !== 'All Agent';

        this.ui.loading = false;
      }, 1000)
    },
    selectProcessor(val) {
      // Func for select report processor
      this.ui.loading = true;
      setTimeout(() => {
        this.typeValue.type = 'processor';
        this.processorData = {id: 1, name: 'Nathan Kowarsky', position: 'Position', ranked: '01'};
        this.reportProcessor = val.title !== 'All Processors';
        this.ui.loading = false;
      }, 1000)
    },
    append() {
      return document.getElementById('pickDate')
    },
    tableFilterAppend() {
      return document.getElementById('table-filter')
    },
    sortableAppend() {
      return document.getElementById('sortable')
    },
  },
  watch: {
    typeValue() {
      this.ui.loading = true;
      this.reportAgent = false;
      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    }
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MMM DD YYYY')
    }
  },
  created() {
    this.setDateList()
  }
}
</script>

<style lang="scss" scoped>
.report-details {
  padding: 18px 30px;
  background-color: #F6F6F6;

  &::v-deep {
    .multiselect {
      min-height: 36px;
      max-height: 36px;
      min-width: 100%;
      width: fit-content;
      @media screen and (max-width: 568px) {
        max-width: 50%;
        min-width: 100%;
      }

      &__tags {
        min-height: 36px;
        max-height: 36px;
        padding: 6px 40px 0 8px;
        transition: all .3s;
      }

      &__select {
        min-height: 36px;
        max-height: 36px;

        &:before {
          top: 15%;
          position: relative;
          right: 0;
          color: #999;
          border: solid rgba(0, 0, 0, 0.46);
          margin-top: 4px;
          border-width: 0px 1px 1px 0;
          display: inline-block;
          padding: 3px;
          content: "";
          transform: rotate(44deg);
        }
      }

      &__single {
        color: rgba(0, 0, 0, 0.46);
      }

      &__option {
        min-height: 36px;
        transition: all .3s;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }

        &--selected {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 18px;
        }

        &--highlight {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 18px;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }
        }
      }
    }
  }
}

.data-category::v-deep {
  .multiselect {
    .multiselect__content-wrapper {
      border-radius: 8px;
    }
    &.multiselect--active {
      .multiselect__tags {
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
        border-radius: 8px;
      }
    }

    &__tags {
      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      color: rgba(0, 0, 0, .7);
      div {
        display: inline;
        margin-left: 15px;
      }
      &--selected {
        background-color: rgba(1, 122, 255, 0.05);
        color: #017AFF;

        div {
          display: inline;
          margin-left: 5px;
        }

        &:before {
          content: '\2714';
          color: #0078FF;
          background: none;
        }
      }

      &--highlight {
        background-color: rgba(1, 122, 255, 0.05);
        color: #017AFF;
      }
    }

    .report-type {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 10px;
    }

    .option__title {
      display: inline;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      margin-left: 10px;
    }
  }
}

.table-filter::v-deep {
  .multiselect {
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      padding: 6px 40px 0 8px;
      transition: all .3s;
      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;
    }

    &__single {
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 24px;
      min-height: 36px;
      transition: all .3s;
      color: rgba(0, 0, 0, .7);
      div {
        display: inline;
        margin-left: 0;
      }

      &--selected {
        background-color: rgba(1, 122, 255, 0.05);
        color: #0a0a0a;
      }

      &--highlight {
        background-color: unset;
        color: #0a0a0a;
      }
    }

    .report-type {
      color: rgba(0, 0, 0, .46);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 10px;

      span {
        display: inline;
        margin-right: 5px;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        opacity: 1;
      }
    }

    .option__title {
      display: inline;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      margin-left: 10px;
    }

    .option-label {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }

    .form-group {
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        font-weight: normal;
        margin-left: 10px;
      }

      .form-check-input {
        width: 18px;
        height: 18px;
        padding: 0;
        max-width: 18px;
        margin: 0;

        &:checked {
          border: 2px solid #017AFF;
          background-color: #017AFF;
        }
      }
    }
  }
}

.setDate-block {
  ul {
    list-style: none;
    padding: 12px 0;

    li {
      color: rgba(0, 0, 0, .7);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: start;
      padding: 9px 30px;
      cursor: pointer;
      transition: all .3s;

      &.is-checked {
        position: relative;
        background-color: rgba(1, 122, 255, .05);
        color: #017AFF;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;

        &:before {
          position: absolute;
          top: 9px;
          left: 10px;
          content: '\2714';
          color: #0078FF;
          background: none;
          margin-right: 6px;
          font-weight: normal;
        }

        span {
          font-weight: normal;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, .05);
        color: rgba(0, 0, 0, 1);
      }

      span {
        color: rgba(0, 0, 0, .46);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
  }
}

.custom-date {
  padding: 17.5px 30px;
  border-top: 1px solid #E7E8E8;
  &::v-deep {
    .daterange-control {
      .reportrange-text {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        height: 36px;
        min-height: 36px;
      }
      img {
        right: 5px;
      }
    }
  }
}

.sortable-block {
  min-width: 202px;
  text-align: start;
  padding: 24px;
  span {
    display: block;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 9px;
  }
  .form-check {
    padding: 0;
    label {
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
    }
    &-input {
      max-width: 18px;
      width: 100%;
      height: 18px;
      margin: 0;
      &[type="radio"] {
        &:hover,
        &:active,
        &:focus {
          box-shadow: none;
        }
        appearance: auto;
      }
      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }
}

#pickDate,
#table-filter,
#sortable {
  position: relative;
  &::v-deep {
    .tippy-popper {
      width: 100%;
      .tippy-tooltip {
        width: 100%;
        max-width: 100% !important;
      }
    }
  }
}
#table-filter {
  .report-type {
    opacity:1;
    color: rgba(0,0,0,.46);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    span {
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      font-weight: normal;
      opacity: 1;
      &.normal {
        color: rgba(0,0,0,.46);
      }
    }
  }
}

</style>
